import React, { FC, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Grid,
  Stack,
  Card,
  CardContent,
  Typography,
  Select,
  Option,
} from '@mui/joy';
import { useMediaQuery } from 'react-responsive';
import {
  apiGetDashboardProducerChart,
  apiGetDashboardWasteChart,
  apiGetDashboardfeeChart,
  apiGetDashboardWasteOverChart,
  apiGetDashboardFeesOverChart,
} from '../../../../apis/organizations';
import { LineChart } from '@mui/x-charts/LineChart';
import { useParams } from 'react-router';

import DashboardChartCard from '../../../../components/widgets/DashboardChartCard';

const DashboardPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const isSm = useMediaQuery({ query: '(max-width: 1224px)' });
  const [wasteCollectType, setWasteCollectType] = useState<string>('week');
  const [feesCollectType, setFeesCollectType] = useState<string>('week');

  const { data: producerChartData, isLoading: producerChartLoading } = useQuery(
    {
      queryKey: ['producerChart', id],
      queryFn: async () => {
        if (id) {
          const data = await apiGetDashboardProducerChart(id);
          return data;
        }
      },
    },
  );

  const { data: wasteChartData, isLoading: wasteChartLoading } = useQuery({
    queryKey: ['wasteChart', id],
    queryFn: async () => {
      if (id) {
        const data = await apiGetDashboardWasteChart(id);
        return data;
      }
    },
  });

  const { data: feeChartData, isLoading: feeChartLoading } = useQuery({
    queryKey: ['feeChart', id],
    queryFn: async () => {
      if (id) {
        const data = await apiGetDashboardfeeChart(id);
        return data;
      }
    },
  });

  const { data: wasteOverChartData, isLoading: wasteOverChartLoading } =
    useQuery({
      queryKey: ['wasteOverChart', id, wasteCollectType],
      queryFn: async () => {
        if (id) {
          const list: any[] = [];
          const data = await apiGetDashboardWasteOverChart(
            id,
            wasteCollectType,
          );
          if (wasteCollectType === 'year') {
            data?.items.forEach((item: any) => {
              list.push({
                label: `${item.month}/${item.year}`,
                total: item.total,
              });
            });
          } else {
            data?.items.forEach((item: any) => {
              list.push({
                label: `${item.month}/${item.day}/${item.year}`,
                total: item.total,
              });
            });
          }

          return {
            name: data.name,
            items: list,
          };
        }
      },
    });

  const { data: feesOverChartData, isLoading: feesOverChartLoading } = useQuery(
    {
      queryKey: ['feesOverChart', id, feesCollectType],
      queryFn: async () => {
        if (id) {
          const list: any[] = [];
          const data = await apiGetDashboardFeesOverChart(id, feesCollectType);
          if (feesCollectType === 'year') {
            data?.items.forEach((item: any) => {
              list.push({
                label: `${item.month}/${item.year}`,
                total: item.total,
              });
            });
          } else {
            data?.items.forEach((item: any) => {
              list.push({
                label: `${item.month}/${item.day}/${item.year}`,
                total: item.total,
              });
            });
          }

          return {
            name: data.name,
            items: list,
          };
        }
      },
    },
  );

  return (
    <>
      <Box mt={4}>
        <Grid container spacing={2}>
          <Grid>
            <DashboardChartCard
              title="Producers"
              total={new Intl.NumberFormat().format(
                producerChartData?.total ?? 0,
              )}
              loading={producerChartLoading}
              percent={producerChartData?.percent}
              items={producerChartData?.items}
            />
          </Grid>
          <Grid>
            <DashboardChartCard
              title="Waste Collected"
              total={`${new Intl.NumberFormat().format(
                wasteChartData?.total ?? 0,
              )} kg`}
              loading={wasteChartLoading}
              percent={wasteChartData?.percent}
              items={wasteChartData?.items}
              description="Last week"
            />
          </Grid>
          <Grid>
            <DashboardChartCard
              title="Fees Collected"
              total={`$ ${new Intl.NumberFormat().format(
                feeChartData?.total ?? 0,
              )}`}
              loading={feeChartLoading}
              percent={feeChartData?.percent}
              items={feeChartData?.items}
            />
          </Grid>
        </Grid>
      </Box>

      <Stack direction={isSm ? 'column' : 'row'} spacing={4} mt={4}>
        <Box sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography level="h4">Waste Collected Over Time</Typography>

                <Select
                  value={wasteCollectType}
                  onChange={(e, newValue: any) => setWasteCollectType(newValue)}
                >
                  <Option value="week">Last Week</Option>
                  <Option value="month">Last Month</Option>
                  <Option value="year">Year-to-Date</Option>
                </Select>
              </Stack>

              <LineChart
                xAxis={[{ dataKey: 'label', scaleType: 'band' }]}
                series={[
                  {
                    dataKey: 'total',
                    label: `${wasteOverChartData?.name ?? ''}`,
                    valueFormatter: (value: any) => `${value} kg`,
                    showMark: false,
                  },
                ]}
                slotProps={{ legend: { hidden: true } }}
                height={300}
                loading={wasteOverChartLoading}
                dataset={wasteOverChartData?.items ?? []}
                colors={['#021B58']}
              />
            </CardContent>
          </Card>
        </Box>

        <Box sx={{ width: '100%' }}>
          <Card>
            <CardContent>
              <Stack direction="row" justifyContent="space-between">
                <Typography level="h4">Fees Collected Over Time</Typography>

                <Select
                  value={feesCollectType}
                  onChange={(e, newValue: any) => setFeesCollectType(newValue)}
                >
                  <Option value="week">Last Week</Option>
                  <Option value="month">Last Month</Option>
                  <Option value="year">Year-to-Date</Option>
                </Select>
              </Stack>

              <LineChart
                xAxis={[{ dataKey: 'label', scaleType: 'band' }]}
                series={[
                  {
                    dataKey: 'total',
                    label: `${feesOverChartData?.name ?? ''}`,
                    valueFormatter: (value: any) => `${value} $`,
                    showMark: false,
                  },
                ]}
                slotProps={{ legend: { hidden: true } }}
                height={300}
                loading={feesOverChartLoading}
                dataset={feesOverChartData?.items ?? []}
                colors={['#021B58']}
              />
            </CardContent>
          </Card>
        </Box>
      </Stack>
    </>
  );
};

export default DashboardPage;
